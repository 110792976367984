import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { bubble as Menu } from "react-burger-menu";
import "../styles/index.scss";
import "../styles/index.sass";



const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        datoCmsSite {
          globalSeo {
            siteName
          }
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
        datoCmsHome {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          copyright
          serving
          soldOut
        }
        allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
          edges {
            node {
              profileType
              url
            }
          }
        }
      }
    `}
    render={data => (
      <div className="wrapper">
        <HelmetDatoCms
          favicon={data.datoCmsSite.faviconMetaTags}
          seo={data.datoCmsHome.seoMetaTags}
          onChangeClientState={(newState, addedTags, removedTags) =>
            console.log(
              // newState, addedTags, removedTags
            )
          }
        />
        <div className="columns is-hidden-tablet">
          <Menu>
            <Link to="/">
              <img alt="Zena B's Raw Honey" title="Zena B's Raw Honey" src="https://www.datocms-assets.com/7775/1539919614-zena-b-raw-honey-logo.svg" />
            </Link>
            <Link id="home" className="menu-item" to="/" activeClassName="activeNavItem">Home</Link>
            <Link id="signup" className="menu-item" to="/signup" activeClassName="activeNavItem">Mailing&nbsp;List</Link>
            <Link id="order" className="menu-item" to="/order" activeClassName="activeNavItem">Order</Link>
            <Link id="honeyTalk" className="menu-item" to="/hive-talk" activeClassName="activeNavItem">Hive Talk</Link>
          </Menu>
        </div>

        <div id="nav" className="columns is-gapless is-hidden-mobile is-mobile">
          <ul className="column is-vcentered is-gapless columns is-6 is-offset-6">
            <li className="column">
              <Link id="home" className="menu-item" to="/" activeClassName="activeNavItem">Home</Link>
            </li>
            <li className="column">
              <Link id="faqs" className="menu-item" to="/#FAQs" activeClassName="activeNavItem">FAQs</Link>
            </li>
            <li className="column">
              <Link id="signup" className="menu-item" to="/signup" activeClassName="activeNavItem">Mailing&nbsp;List</Link>
            </li>
            <li className="column">
              <Link id="order" className="menu-item" to="/order" activeClassName="activeNavItem">Order</Link>
            </li>
            <li className="column">
              <Link id="honeyTalk" className="menu-item" to="/hive-talk" activeClassName="activeNavItem">Hive Talk</Link>
            </li>
          </ul>
        </div>

        {children}

        <div className="footer columns is-gapless">
          <div className="footer-flower" />
          <div className="column">
            <div className="columns is-centered">
              <div className="column is-half serving">
                <h2 className="script">{data.datoCmsHome.serving}</h2>
              </div>
            </div>

            <div className="columns is-full">
              <div className="column burstall is-4 is-offset-4">
                <p>Proudly made in</p>
                <h3>Burstall, SK Canada</h3>
              </div>
              <div className="column is-2 is-offset-2 bottom-bee">
                <img alt="Happy bee" title="Happy bee" src="https://www.datocms-assets.com/7775/1540094143-bee-chubby.png" />
              </div>
            </div>

            <div className="copyright">
              {/*
              {(() => {
                <script type="text/javascript">
                document.write(new Date().getFullYear());
                </script>
              })()} */}
              {data.datoCmsHome.copyright}</div>
          </div>
        </div>
      </div>
    )}
  />
);

TemplateWrapper.propTypes = {
  children: PropTypes.object
};

export default TemplateWrapper;
